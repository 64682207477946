import {makeStyles, styled} from "@mui/styles";
import {alpha, TextareaAutosize} from "@mui/material";

const BASEURL = 'https://erp.ivorymontessorisystem.com';
const deco = BASEURL + '/images/decoration/hexaDecoration.svg';


   /* const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };*/


    // @ts-ignore
    export const StyledTextarea = styled(TextareaAutosize)( // @ts-ignore
        ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: grey[900];
    background: '#fff';
    border: 1px solid grey[200];
    box-shadow: 0px 2px 2px grey[50];

    &:hover {
      border-color: blue[400];
    }

    &:focus {
      border-color: {blue[400];
      box-shadow: 0 0 0 3px  blue[200];
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
    );

const documentStyles = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            fontSize: '14px',
            '& .css-1humz1n-MuiTypography-root': {
                margin: 0,
                fontSize: '0.9rem',
                display: 'block',
                marginTop: 5,
            },'& .rbc-allday-cell ': {
                display: 'none',
            },'& .rbc-event-label ': {
                color: '#424242',
            }, '& .rbc-event, .rbc-day-slot .rbc-background-event ': {
                border: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
                margin: 0,
                padding: '2px 5px',
                backgroundColor: 'transparent',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left'
            }
        },
        spacingGridContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        containerBtnFileFolder: {
            display: 'flex',
            flexDirection: 'column',
            wordWrap:'break-word',
            alignItems: 'center',
            '& span': {
                textTransform: 'capitalize'
            },
        },
        rdvBadgeList:{
            display:'inline-block',
            position:'absolute',
            top:-8,
            right:15,
        },
        rdvList: {
            height: 600,
            overflow:'auto',
            '& > div': {
                borderRadius: 8,
                padding: theme.spacing(1),
                margin: `${theme.spacing(1)} 0`
            },
            '& $textNotSelected':{
                color: theme.palette.text.secondary
            }
        },
        selected: {
            background: alpha(theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.background.paper, 0.8),
            '& span': {
                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark
            },
            '& svg': {
                fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark
            },
            '&:focus, &:hover': {
                background: alpha(theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.background.paper, 0.8),
            }
        },
        btnArea: {
            display: 'flex',
            justifyContent: 'center',
            margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
            fontSize: 12,
            '& $label': {
                fontSize: 12,
                '& span': {
                    fontSize: 12,
                },
            },
            '& button': {
                margin: `0 ${theme.spacing(1)}px`,
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                '& button': {
                    width: '100%',
                    margin: 5,
                },
            },
        },
        title: {fontWeight: 500,},
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        actionIcon:{
            marginRight:7,
            marginTop:-1
        },
        selectMenuItem:{
            fontWeight:400,
        },
        wrapper: {
            fontFamily: theme.typography.fontFamily,
            position: 'relative',
            marginLeft: theme.spacing(1),
            borderRadius: theme.spacing(1),
            display: 'inline-block',
            background: alpha(theme.palette.text.primary, 0.05),
            [`& .miniInput`]: {
                width: 70
            },
        },
        searchWrapper: {
            [theme.breakpoints.down('lg')]: {
                flex: 1,
            }
        },
        search: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                color: theme.palette.grey[400]
            },
            /*[theme.breakpoints.down('sm')]: {
                display: 'none'
            },*/
        },
        miniInput: {
            paddingLeft: 0,
            textIndent: '999999px'
        },
        titleUserAppointment: {
            position: 'relative',
            fontSize: '18px !important',
            fontWeight: 700,
            color: alpha(theme.palette.text.primary, 0.7),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: theme.spacing(1)
            },
        },
        description: {
            maxWidth: 960,
            paddingTop: theme.spacing(0.5),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        userDetails:{
            marginBottom : theme.spacing(3),
            padding: 10,
            paddingLeft:15,
            backgroundColor:"#F5F5F5",
        },
        userInfoBox:{
            flex:1,
            display: 'flex',
            padding: 5,
            overflow:'hidden',
            marginTop: theme.spacing(2),
        },
        customAvatar:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(0),
        },
        containerTitle: {
            width: '100%',
            '& .css-18eur9w-MuiInputBase-root-MuiOutlinedInput-root ': {
                fontWeight: 400,
                padding: 0,
            },
            '& .css-ovmfc2-MuiInputBase-root-MuiOutlinedInput-root': {
                fontWeight: 400,
                padding: 0,
                borderColor: theme.palette.secondary.main,
            }
        },
        customTitle: {
            flex:2,
            paddingTop:7,
            color: alpha(theme.palette.text.primary, 0.7),
        },
        customTitlePreset: {
            paddingTop:7,
            color: alpha(theme.palette.text.primary, 0.7),
        },
        titleUserInfo: {
            fontSize: '16px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px !important',
            },
            fontWeight: '700 !important',
        },
        toolbar: {
            marginBottom: theme.spacing(3),
            '& .css-1ijs4i7-MuiInputBase-root-MuiOutlinedInput-root ':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-1hcsjek-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-cs5oy5-MuiButtonBase-root-MuiButton-root':{
                fontSize: '1rem',
            },
        },
        inputField: {
            marginBottom: 15,
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input':{
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft:0,
                paddingRight:0
            }
        },
        autoCompleteInput: {
            '& .css-1gul9cr-MuiAutocomplete-root, & .MuiAutocomplete-input  ': {
                fontWeight: 'normal',
            }
        },
        '& .css-1j6gdzv-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '0.9rem',
            fontWeight: 400,
            borderRadius: '4px',
            paddingLeft: '0px !important',
            marginBottom:5,
            marginTop:5,
            border: 'none'
        },

        demo: {
            height: 'auto',
        },
        divider: {
            margin: `${theme.spacing(3)} 0`,
        },
        picker: {
            margin: `${theme.spacing(3)} 5px`,
        },
        eventContainerNormal: {
            backgroundColor: theme.palette.primary.main,
            padding: 2,
            borderRadius: 10,
        },
        eventContainerPreset: {
            backgroundColor: "#8E24AA",
            padding: 2,
            borderRadius: 10,
        },
        documentTitle: {
            backgroundColor: "transparent",
            display:'inline-block',
            fontSize:14,
            fontWeight:400, color:'white',
        },
        headerTools:{
            marginBottom:0,
            padding:0,
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        contentDetails:{
            paddingBottom:10,
            fontSize:14,
            lineHeight:1.5,
        },
        appointmentTitleDetails: {
            padding: 0,
            fontSize:15,
            fontWeight:600,
        },
        actionTools: {
            display: 'inline-block',
            width:'22%',
            textAlign:'right'
        },
        itemDetail: {},
        labelDetail: {
            display: 'inline-block',
            width:'30%'
        },
        infoDetail: {
            display: 'inline-block',
            width:'70%'
        },
        interlocutor: {
            display: 'inline-block',
            width:'80%'
        },
        statusDetail:{
            display: 'inline-block',
            width:'80%'
        },
        timeDetail: {
            display: 'inline-block',
            width:'30%'
        },
        timeDeprecated: {
            textDecoration: "line-through",
        },
        errorHelper:{
            color: '#FF0000',
            fontSize: 13,
            paddingTop:7,
            paddingLeft: 2,
        },
        errorMessage: {
            flex:1,
            color: '#FF0000',
            fontSize: 14,
            marginBottom: 15,
        }


    }
})

export default documentStyles ;
