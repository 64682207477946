import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import moment from 'moment'
import {momentLocalizer} from "react-big-calendar";
import BigCalendar from "../../components/ui/calendar/BigCalendar";
import myEventsList from "../../data/Event"
import {alpha, Avatar, Box, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import AppointmenToolBar from "../../components/ui/calendar/AppointmenToolBar";
import classNames from "classnames";
import {usersList} from '../../data/countries'
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import InputField from "../../components/ui/form/InputField";
import DialogForm from "../../components/ui/form/DialogFormKiller";
import FormControl from "@mui/material/FormControl";
import UserAutoCompleteInput from "../../components/ui/form/UserAutoCompleteInput";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import 'moment/locale/en-gb';
import 'moment/locale/fr'
import PopperBlock from "./PopperBlock";


const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            fontSize: '14px',
            '& .css-1humz1n-MuiTypography-root': {
                margin: 0,
                fontSize: '0.9rem',
                display: 'block',
                marginTop: 5,
            },
            '& .rbc-event, .rbc-day-slot .rbc-background-event ': {
                border: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
                margin: 0,
                padding: '2px 5px',
                backgroundColor: 'transparent',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left'
            }
        },
        title: {},
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        actionIcon:{
            marginRight:7,
            marginTop:-1
        },
        appointmenToolbar: {
            marginBottom: theme.spacing(3),
            '& .css-1ijs4i7-MuiInputBase-root-MuiOutlinedInput-root ':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-1hcsjek-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-cs5oy5-MuiButtonBase-root-MuiButton-root':{
                fontSize: '1rem',
            },
        },
        selectMenuItem:{
            fontWeight:400,
        },
        wrapper: {
            fontFamily: theme.typography.fontFamily,
            position: 'relative',
            marginLeft: theme.spacing(1),
            borderRadius: theme.spacing(1),
            display: 'inline-block',
            background: alpha(theme.palette.text.primary, 0.05),
            [`& .miniInput`]: {
                width: 70
            },
        },
        searchWrapper: {
            [theme.breakpoints.down('lg')]: {
                flex: 1,
            }
        },
        search: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                color: theme.palette.grey[400]
            },
            /*[theme.breakpoints.down('sm')]: {
                display: 'none'
            },*/
        },
        miniInput: {
            paddingLeft: 0,
            textIndent: '999999px'
        },
        titleUserAppointment: {
            position: 'relative',
            fontSize: '18px !important',
            fontWeight: 700,
            color: alpha(theme.palette.text.primary, 0.7),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: theme.spacing(1)
            },
        },
        description: {
            maxWidth: 960,
            paddingTop: theme.spacing(0.5),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        userDetails:{
            marginBottom : theme.spacing(3),
            padding: 10,
            paddingLeft:15,
            backgroundColor:"#F5F5F5",
        },
        userInfoBox:{
            flex:1,
            display: 'flex',
            padding: 5,
            overflow:'hidden',
            marginTop: theme.spacing(2),
        },
        customAvatar:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(0),
        },
        customTitle: {
            flex:1,
            paddingTop:7,
            color: alpha(theme.palette.text.primary, 0.7),
        },
        titleUserInfo: {
            fontSize: '16px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px !important',
            },
            fontWeight: '700 !important',
        },
        inputField: {
            marginBottom: 15,
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input':{
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft:0,
                paddingRight:0
            }
        },
        autoCompleteInput: {
            '& .css-1gul9cr-MuiAutocomplete-root, & .MuiAutocomplete-input  ': {
                fontWeight: 'normal',
            }
        },
        '& .css-1j6gdzv-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '0.9rem',
            fontWeight: 400,
            borderRadius: '4px',
            paddingLeft: '0px !important',
            marginBottom:5,
            marginTop:5,
            border: 'none'
        },

        demo: {
            height: 'auto',
        },
        divider: {
            margin: `${theme.spacing(3)} 0`,
        },
        picker: {
            margin: `${theme.spacing(3)} 5px`,
        },
        eventContainer: {
            backgroundColor: theme.palette.secondary.main,
            padding: 2,
            borderRadius: 10,
        },
        eventCancel: {
            width:12,
            height:12,
            display:'inline-block',
            marginRight:10,
            marginLeft:5,
            marginTop:2,
            borderRadius:30,
            backgroundColor:'red',
        },
        eventValidate: {
            width:12,
            height:12,
            display:'inline-block',
            marginRight:10,
            marginLeft:5,
            marginTop:2,
            borderRadius:30,
            backgroundColor:'lightgreen',
        },
        eventPending: {
            width:12,
            height:12,
            display:'inline-block',
            marginRight:10,
            marginLeft:5,
            marginTop:2,
            borderRadius:30,
            backgroundColor:'orange',
        },
        appointmentTitle: {
            backgroundColor: theme.palette.secondary.main,
            display:'inline-block',
            fontSize:13,
            fontStyle:'italic',
            fontWeight:400,
            color:'white',
        },
        headerTools:{
            marginBottom:0,
            padding:0,
        },
        contentDetails:{
            paddingBottom:10,
            fontSize:14,
            lineHeight:1.5,
        },
        appointmentTitleDetails: {
            padding: 0,
            fontSize:15,
            fontWeight:600,
        },
        actionTools: {
            display: 'inline-block',
            width:'22%',
            textAlign:'right'
        },
        itemDetail: {},
        labelDetail: {
            display: 'inline-block',
            width:'30%'
        },
        infoDetail: {
            display: 'inline-block',
            width:'70%'
        },
        interlocutor: {
            display: 'inline-block',
            width:'80%'
        },
        statusDetail:{
            display: 'inline-block',
            width:'70%'
        },
        timeDetail: {
            display: 'inline-block',
            width:'70%'
        }

    }
})

interface UserType {
    id: number,
    firstName: string;
    lastName: string;
    profile: string;
    class?: string;
    username?: string;
    image?: string;
}

const teachers = [
    {
        id:1,
        firstName:"Nicole",
        lastName:"Kodjo",
        profile: "teacher",
        class:"Acajou",
        username:"atuny0",
        image:"https://ivorymontessorisystem.com/media/upload/photo/Nicole.png",
    },
    {
        id:2,
        firstName:"Ange",
        lastName:"Koffi",
        profile: "teacher",
        class:"Bamboo",
        username:"atuny0",
        image:"https://ivorymontessorisystem.com/media/upload/photo/Ange.png",
    },
    {
        id:3,
        firstName:"Gloria",
        lastName:"Moukouri",
        profile: "teacher",
        class:"Baobab",
        username:"atuny0",
        image:"https://ivorymontessorisystem.com/media/upload/photo/Gloria.png",
    },
];

const children = [
    {
        id:15,
        firstName:"Ali",
        lastName:"Dekmak",
        profile: "children",
        class:"Iroko",
        username:"umcgourty9",
        image:"https://ivorymontessorisystem.com/media/upload/child_221.jpeg",
    },
    {
        id:16,
        firstName:"Alyona",
        lastName:"Amata",
        profile: "children",
        class:"Bamboo",
        username:"umcgourty9",
        image:"https://ivorymontessorisystem.com/media/upload/child_222.jpeg",
    },
    {
        id:16,
        firstName:"Azzaria",
        lastName:"Mahtani",
        profile: "children",
        class:"Samba",
        username:"umcgourty9",
        image:"https://ivorymontessorisystem.com/media/upload/child_122.jpeg",
    },
];

const classroomList = [
    {
        id: 1,
        name: "Acajoo",
        room: 1,
    },
    {
        id: 2,
        name: "Baobab",
        room: 2,
    },
    {
        id: 3,
        name: "Bamboo",
        room: 3,
    },
    {
        id: 4,
        name: "Iroko",
        room: 3,
    },
    {
        id: 5,
        name: "Samba",
        room: 3,
    },
]

function AppointmentCalendar(props:any){
    const { locale, theme } = props;
    const classes = useStyles();
    const activeLanguage = locale.split('-');
    const localizer = momentLocalizer(moment);
    const [userInfoStatus, setUserInfoStatus] = useState(false);
    const [user, setUser] = useState<UserType>({id:0, firstName:'Firstname', lastName:'Lastname', profile:'Admin'});
    const users:UserType[] = usersList;
    const [open, setOpen] = React.useState(false);
    const [teacher, setTeacher] = React.useState('');
    const [child, setChild]  = React.useState('');
    const placeholderTeacher = activeLanguage[0] === 'en' ? 'Select a teacher' : 'Sélectionnez un enseignant';
    const placeholderChild = activeLanguage[0] === 'en' ? 'Select a child' : 'Sélectionnez un enfant';
    const [startDate, setStartDate] = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(startDate);
    const [presetStatus, setPresetStatus] = useState(true);
    const [classeSelect, setClasseSelect] = useState('');
    const [appointmenType, setAppointmenType]  = useState(1);
    //const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    //const [openPopper, setOpenPopper] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const addButton = () => {
        handleClickOpen()
    }

    const editAppointment = () => {
        handleClickOpen()
    }

    const handleTeacherChange = (user: any) => {
        if(user !== null) {
            setTeacher(user)
        }
    }

    const handleChildChange = (child: any) => {
        if(child !== null) {
            setChild(child)
        }
    }

    const handleStartDateChange = (date:any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date:any) => {
        setEndDate(date)
    }

    const handleAppointmentTypeChange = (event:any) => {
        setPresetStatus(!presetStatus)
        setAppointmenType(event.target.value)
    }

    const handleClassChange = (event:any) => {
        setClasseSelect(event.target.value)
    }

    const onSubmit = () => {
    }


    const MonthEvent = ({ event }:{event:any}) => {

        return (
            <PopperBlock classes={classes} event={event} theme={theme} editAppointment={editAppointment} />
        )
        /*const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            //console.log(event.currentTarget);
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };


        const openPopper = Boolean(anchorEl);
        const id = openPopper ? 'simple-popover' : undefined;

        return (
            <Box className={classes.eventContainer} onClick={(event:any) => handleClick(event)}>
                <div>
                    { event.status === 'validate' && (<div className={classes.eventValidate}></div>) }
                    { event.status === 'pending' && (<div className={classes.eventPending}></div>) }
                    { event.status === 'cancel' && (<div className={classes.eventCancel}></div>) }
                    <div style={{backgroundColor: theme.palette.secondary.main, display:'inline-block'}}>{event.title}</div>
                    <Popover
                        //id={id}
                        open={openPopper}
                        anchorEl={anchorEl}
                        onClose={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorEl(null);
                        }}

                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ width:300}}>
                            <Typography sx={{ p: 2 }}>{event.title}</Typography>
                            <IconButton onClick={handleClose}><Add /></IconButton>
                        </Box>

                    </Popover>
                </div>


                {/!*<PopperTest />*!/}

            </Box>
        );*/
    };



    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.root}>
                <div className={classes.appointmenToolbar}>
                    <Box>
                        <AppointmenToolBar
                            locale={locale}
                            classes={classes}
                            users={users}
                            setUserInfoStatus={setUserInfoStatus}
                            setUser={setUser}
                            open={open}
                            setOpen={setOpen}
                            addButton={addButton}
                        />
                    </Box>
                </div>

                <Box className={classes.userDetails}
                     sx={{display:  userInfoStatus ? 'block' : 'none' }}
                >
                    <Typography variant="h6" component="h2" className={classes.titleUserAppointment}>
                        <FormattedMessage id={messages.user_appointment_list} />
                    </Typography>
                    <Box className={classNames(classes.userInfoBox)}>
                        <Avatar className={classes.customAvatar}
                                src={user.image}
                                sx={{width:56, height:56}}
                                alt="user photo"
                        />
                        <Box className={classes.customTitle} letterSpacing={1}>
                            <Box>
                                <Typography className={classes.titleUserInfo} variant="subtitle1">
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <span style={{textTransform: "capitalize"}}>{user.profile}</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <BigCalendar
                    localizer={localizer}
                    events={myEventsList}
                    locale={locale}
                    addButton={addButton}
                    components={{event: MonthEvent}}  />
                {/*{onSelectEvent={handleClick}}*/}
            </div>

            <DialogForm title={<FormattedMessage id={messages.normal_title} />} open={open} setOpen={setOpen} theme={theme} onSubmit={onSubmit}>
                <div className={classes.inputField}>
                    <FormControl>
                        <RadioGroup
                            row
                            defaultValue={appointmenType}
                            name="radio-buttons-group"
                            value={appointmenType}
                            onChange={handleAppointmentTypeChange}
                        >
                            <FormControlLabel
                                value="1"
                                control={<Radio color={"secondary"} />}
                                label={<FormattedMessage id={messages.appointment_radio} />}
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio color={"secondary"} />}
                                label={<FormattedMessage id={messages.preset_appointment} />}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>

                <Box
                    sx={{
                        display: presetStatus ? 'block' : 'none'
                    }}
                >
                    <div className={classNames(classes.inputField, classes.autoCompleteInput)}>
                        <UserAutoCompleteInput
                            data={teachers}
                            onUserChange={handleTeacherChange}
                            placeholderText={placeholderTeacher}
                        />
                    </div>
                </Box>

                <Box
                    sx={{
                        display: presetStatus ? 'block' : 'none'
                    }}
                >
                    <div className={classNames(classes.inputField, classes.autoCompleteInput)}>
                        <UserAutoCompleteInput
                            data={children}
                            onUserChange={handleChildChange}
                            placeholderText={placeholderChild}
                        />
                    </div>
                </Box>

                <Box
                    sx={{
                        display: presetStatus ? 'none' : 'block'
                    }}
                >
                    <div className={classes.inputField}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.class_preset} />}
                            value={classeSelect}
                            sx={{ width: { xs: '100% !important'}, }}
                            color={"secondary"}
                            onChange={(a:any) => handleClassChange(a) }
                        >
                            {classroomList.map((option:any) => (
                                <MenuItem key={option.id} value={option} sx={{fontWeight:400}}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </Box>

                <div className={classes.inputField}>
                    <InputField
                        label={<FormattedMessage id={messages.title_appointment} />}
                        size="small"
                        color={"secondary"}
                        width={'100% !important'}
                    />
                </div>

                <div className={classes.inputField}>
                    {/*<InputField
                        label={<FormattedMessage id={messages.description_appointment} />}
                        size="small"
                        color={"secondary"}
                        multiline
                    />*/}
                </div>

                <div className={classes.inputField}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                        <MobileDateTimePicker
                            label={<FormattedMessage id={messages.start_day} />}
                            value={startDate}
                            onChange={handleStartDateChange}
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            slotProps={{ textField: { color: 'secondary' } }}
                        />
                    </LocalizationProvider>
                </div>

                <div className={classes.inputField}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                        <MobileDateTimePicker
                            label={<FormattedMessage id={messages.end_day} />}
                            value={endDate}
                            onChange={handleEndDateChange}
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            slotProps={{ textField: { color: 'secondary' } }}
                            minDateTime={startDate}
                        />
                    </LocalizationProvider>
                </div>

                <Box
                    sx={{
                        display: presetStatus ? 'none' : 'block'
                    }}
                >
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.duration_preset} />}
                            type="number"
                            color={"secondary"}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </div>
                </Box>

            </DialogForm>




        </PapperBlock>
    )
}

export default AppointmentCalendar
